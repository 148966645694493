@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100..900;1,100..900&display=swap");

.zvezda {
  top: calc(20% - 333px);
  left: calc(25% + 350px);
}

.tab-switcher .active {
  border-bottom: 2px solid #2f3544;
  margin-bottom: -2px;
}

.search-btn {
  width: 3rem;
  height: 2.5rem;
}

.rating-icon {
  width: 3.3125rem;
  height: 3.3125rem;
}

.bg-gray {
  background-color: #a9a9a924;
}

.vpn-logo {
  width: 14rem;
  height: 8rem;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media (max-width: 960px) {
  .zvezda {
    top: auto;
    left: auto;
  }
}

.modal-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: #00000047;
}

.modal-container .modal-sub-container {
  width: 100%;
  background: #ecf0f7;
  max-height: 100%;
  border-radius: 0.5rem;
  text-align: center;
  position: relative;
}


.modal-close {
  top: 2rem;
  right: 2rem;
  position: absolute;
  z-index: 2;
}

@media (min-width: 80rem) {
  .modal-container .modal-sub-container {
    max-width: 80rem;
  }
}